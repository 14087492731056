import Vue from "vue";
import "./assets/css/normalize.css";
import "./assets/css/common.styl";
import "./assets/icon/UnidreamLED.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getToken } from "@/utils/auth";
import "babel-polyfill";
import "element-ui/lib/theme-chalk/base.css";
// import "@/utils/draggabel";
import "@/utils/draggabelBox";


// 图片预览
// import "viewerjs/dist/viewer.css";
// API方式访问
import { api as viewerApi } from "v-viewer";
Vue.prototype.$viewerApi = viewerApi;


import scroll from "vue-seamless-scroll";
import "@/assets/styl/index.styl";
import permission from "./components/permission";
Vue.use(permission);
import { Loading, Message, MessageBox } from "element-ui";
Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading.service;
//无定时器关闭
Vue.prototype.Loading = function(name) {
  const loading = this.$loading({
    // 声明一个loading对象
    lock: true, // 是否锁屏
    text: "正在加载...", // 加载动画的文字
    spinner: "el-icon-loading", // 引入的loading图标
    // background: "rgba(0, 0, 0, 0.3)", // 背景颜色
    background: "rgba(0, 0, 0, 0.7)", // 背景颜色
    target: "." + name, // 需要遮罩的区域
    body: true,
    customClass: "mask" // 遮罩层新增类名
  });
  return loading;
};
//有定时器关闭
Vue.prototype.openLoading = function(name) {
  const loading = this.$loading({
    // 声明一个loading对象
    lock: true, // 是否锁屏
    text: "正在加载...", // 加载动画的文字
    spinner: "el-icon-loading", // 引入的loading图标
    // background: "rgba(0, 0, 0, 0.3)", // 背景颜色
    background: "rgba(0, 0, 0, 0.7)", // 背景颜色
    target: "." + name, // 需要遮罩的区域
    body: true,
    customClass: "mask" // 遮罩层新增类名
  });
  setTimeout(function() {
    // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close(); // 关闭遮罩层
  }, 10000);
  return loading;
};
Vue.prototype.$ELEMENT = { size: "small" };
Vue.use(scroll);
Vue.use(scroll, { componentName: "scroll-seamless" });

import "./assets/icon/iconfont.css";
import "./assets/css/main.css";

import "@/assets/icons/index"; // svg
// 导入常用方法
import custom from "@/utils/custom";
Object.keys(custom).forEach(key => {
  Vue.prototype["$" + key] = custom[key];
});
Vue.prototype.$getToken = getToken();
import { roleMixin } from "@/utils/roleMixin";
Vue.mixin(roleMixin);
// 自定义指令
import directives from "./directives";
Vue.use(directives);
// 按需引用element插件
import { registerElement } from "@/plugins/registerElement";
registerElement(Vue);
//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  const hasToken = getToken();
  // MessageBox.confirm("此操作将删除该文件, 是否继续?", "提示", {
  //   confirmButtonText定: "确",
  //   cancelButtonText: "取消",
  //   type: "warning"
  // }).then(() => {
  //   console.log("确定");
  // });
  if (!hasToken) {
    if (
      ["/login", "/home", "/404", "/forgetPwd", "/register", "/share"].indexOf(
        to.path
      ) > -1
    ) {
      //无需登陆的路由
      next();
    } else {
      if (to.fullPath != "/login") {
        next(`/login?redirect=${to.fullPath}`);
      } else {
        next("/login");
      }
    }
  } else {
    next();
  }

  //路由发生改变修改页面的title
  if (to.meta.title) {
    document.title = window.configData.webTitle +  to.meta.title;
  }
});
import "./directives/directive";
import draggDialog from "./directives/draggDialog";
import Axios from "axios";
Axios.defaults.baseURL = window.configData.VUE_APP_BASEURL;
Vue.use(draggDialog);
const VueApp = new Vue({
  router,
  store,
  render: h => h(App)
});
VueApp.$mount("#app");
